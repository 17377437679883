/* this file is transformed by vux-loader */
/* eslint-disable */
import _createForOfIteratorHelper from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _toConsumableArray from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Cell from 'vux/src/components/cell';
import Checker from 'vux/src/components/checker/checker';
import CheckerItem from 'vux/src/components/checker/checker-item';
import CheckIcon from 'vux/src/components/check-icon';
import Group from 'vux/src/components/group';
import Popup from 'vux/src/components/popup';
import PopupHeader from 'vux/src/components/popup-header';
import Search from 'vux/src/components/search';
import ViewBox from 'vux/src/components/view-box';
import XButton from 'vux/src/components/x-button';
import TransferDom from 'vux/src/directives/transfer-dom';
export default {
  name: 'OrgTree',
  directives: {
    TransferDom: TransferDom
  },
  components: {
    Cell: Cell,
    Group: Group,
    ViewBox: ViewBox,
    Search: Search,
    XButton: XButton,
    CheckIcon: CheckIcon,
    Checker: Checker,
    CheckerItem: CheckerItem,
    PopupHeader: PopupHeader,
    Popup: Popup
  },
  props: {
    value: Boolean,
    depSerial: String,
    depName: String,
    selectType: {
      type: Number,
      default: 9999
    },
    infoChecked: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      timeh: '',
      depList: [],
      userList: [],
      depChecked: [],
      userChecked: [],
      searchList: [],
      userSubList: [],
      depSubList: [],
      selectList: [],
      userInfo: [],
      userSum: 0,
      depSum: 0,
      allCheck: false,
      searchValue: '',
      searchFlag: false,
      detailFlag: false,
      showFlag: false,
      pagePath: [],
      pageIndex: 1,
      depSerials: '',
      allyeshu: 1,
      initFlag: false,
      xi: false
    };
  },
  computed: {
    bodyBottom: function bodyBottom() {
      return this.selectType !== 1 ? '0.41rem' : '0.41rem';
    },
    btnBottom: function btnBottom() {
      return this.selectType !== 1 ? 'padding-bottom:0.065rem' : 'padding-bottom:0.355rem';
    },
    depCheck: function depCheck() {
      return function (obj) {
        var temp = this.depChecked.filter(function (item) {
          return item === obj;
        });
        return temp.length > 0;
      };
    },
    userCheck: function userCheck() {
      return function (obj) {
        var temp = this.userChecked.filter(function (item) {
          return item === obj;
        });
        return temp.length > 0;
      };
    }
  },
  methods: {
    huoqu: function huoqu() {
      var _this2 = this;

      var _this = this;

      var params = {
        pageIndex: _this.pageIndex,
        pageSize: 10,
        search: {
          depParent: _this.depSerials
        }
      };

      if (_this.xi) {
        return;
      }

      _this.$api.Org.getUserInfos(params).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);
        } else {
          // 获取人员
          _this.userList = [].concat(_toConsumableArray(_this.userList), _toConsumableArray(data.records));
          _this.initFlag = true;
          _this.pageIndex++;
          _this.allyeshu = data.totalPage;

          if (_this.pageIndex >= data.totalPage + 1) {
            _this.xi = true;
            return;
          }
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    },
    delUser: function delUser(obj) {
      var index = this.userChecked.indexOf(obj);

      if (index > -1) {
        this.userChecked.splice(index, 1);
      }

      this.userInfo = this.userInfo.filter(function (user) {
        return user.userSerial !== obj;
      });
    },
    delOrg: function delOrg(obj) {
      var index = this.depChecked.indexOf(obj);

      if (index > -1) {
        this.depChecked.splice(index, 1);
      }
    },
    onDetail: function onDetail() {
      if (this.userSum + this.depSum === 0) {
        return;
      }

      this.userSelect();
      this.detailFlag = true;
    },
    onJump: function onJump(dep) {
      this.pageIndex = 1;
      this.xi = false;
      this.getOrgList(dep.depSerial, dep.depName, true);
      this.depSerials = dep.depSerial;
    },
    onCancel: function onCancel() {
      this.searchFlag = false;
    },
    onSubmit: function onSubmit(value) {
      var _this3 = this;

      this.searchFlag = true;
      this.$utils.Tools.loadShow();
      this.$api.Org.getUserList(value).then(function (response) {
        _this3.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this3.$code.success) {
          _this3.$utils.Tools.toastShow(msg);
        } else {
          _this3.searchList = data;
        }
      }).catch(function () {
        _this3.$utils.Tools.httpError();
      });
    },
    onDepCheck: function onDepCheck() {
      var _this4 = this;

      if (this.selectType === 1) {
        return;
      }

      var depTemp = this.depList.filter(function (dep) {
        return _this4.depChecked.indexOf(dep.depSerial) > -1;
      });
      depTemp.filter(function (item) {
        var obj = JSON.stringify({
          depSerial: item.depSerial,
          depName: item.depName,
          userNums: item.userNums
        });

        var index = _this4.depSubList.indexOf(obj);

        if (index > -1) {
          _this4.depSubList.splice(index, 1);
        }

        _this4.depSubList.push(obj);
      });
      this.depSubList = this.depSubList.filter(function (item) {
        var obj = JSON.parse(item);
        return _this4.depChecked.indexOf(obj.depSerial) > -1;
      });
      this.depSum = 0;
      this.depSubList.filter(function (item) {
        var obj = JSON.parse(item);
        _this4.depSum = _this4.depSum + obj.userNums;
      });
    },
    onUserCheck: function onUserCheck() {
      this.userSum = 0;
      var that = this;
      that.userChecked.filter(function () {
        that.userSum = that.userSum + 1;
      });

      if (that.initFlag && that.selectType === 1) {
        that.onSelect();
      }
    },
    userSelect: function userSelect() {
      var _this5 = this;

      this.userInfo = [];

      if (this.userChecked.length > 0) {
        this.$utils.Tools.loadShow();
        this.$api.Org.getUserInfo(JSON.stringify(this.userChecked)).then(function (response) {
          _this5.$utils.Tools.loadHide();

          var code = response.code,
              msg = response.msg,
              data = response.data;

          if (+code !== _this5.$code.success) {
            _this5.$utils.Tools.toastShow(msg);
          } else {
            _this5.userInfo = data;
          }
        }).catch(function () {
          _this5.$utils.Tools.httpError();
        });
      }
    },
    onClose: function onClose() {
      this.showFlag = false;
    },
    onSelect: function onSelect() {
      var _that = this;

      function userHandler() {
        var usersInfo = [];

        if (_that.userChecked.length > 0) {
          //先选择人员
          _that.$utils.Tools.loadShow();

          return _that.$api.Org.getUserInfo(JSON.stringify(_that.userChecked)).then(function (response) {
            _that.$utils.Tools.loadHide();

            var code = response.code,
                msg = response.msg,
                data = response.data;

            if (+code !== _that.$code.success) {
              _that.$utils.Tools.toastShow(msg);
            } else {
              usersInfo.push.apply(usersInfo, _toConsumableArray(data));
              return usersInfo;
            }
          }).catch(function () {
            return Promise.reject();
          });
        } else {
          return Promise.resolve(usersInfo);
        }
      }

      function depHandler(usersInfo) {
        if (_that.depChecked.length > 0 && _that.selectType !== 1) {
          //再选择部门
          _that.$utils.Tools.loadShow();

          return _that.$api.Org.getUserByOrg(JSON.stringify(_that.depChecked)).then(function (response) {
            _that.$utils.Tools.loadHide();

            var code = response.code,
                msg = response.msg,
                data = response.data;

            if (+code !== _that.$code.success) {
              _that.$utils.Tools.toastShow(msg);
            } else {
              usersInfo.push.apply(usersInfo, _toConsumableArray(data));
              return usersInfo;
            }
          }).catch(function () {
            return Promise.reject();
          });
        } else {
          return Promise.resolve(usersInfo);
        }
      }

      function rtnHandler(usersInfo) {
        var _iterator = _createForOfIteratorHelper(usersInfo),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var item = _step.value;

            _that.selectList.push({
              userName: item.userLname,
              userSerial: item.userSerial,
              userTelephone: item.userTelephone,
              userDepname: item.userDepname
            });
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }

        _that.showFlag = false;

        _that.$emit('onSelect', _that.selectList);
      }

      userHandler().then(depHandler).then(rtnHandler).catch(this.$utils.Tools.httpError);
    },
    getOrgList: function getOrgList(depSerial, depName, flag) {
      var _this6 = this;

      if (flag) {
        var dep = {
          depSerial: depSerial,
          depName: depName
        };
        this.pagePath.push(dep);
      } else {
        var _flag = false;
        var _index = 0;

        for (var index in this.pagePath) {
          _index = index;

          if (this.pagePath[index].depSerial === depSerial) {
            _flag = true;
            break;
          }
        }

        if (_flag) {
          this.pagePath.splice(+_index + 1, this.pagePath.length - _index - 1);
        }
      } // 直接去掉用获取人员接口


      this.depSerials = depSerial;
      this.$api.Org.getdepParentNo(depSerial).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this6.$code.success) {
          _this6.$utils.Tools.toastShow(msg);
        } else {
          // 获取下级部门
          _this6.depList = data.depList;
          _this6.initFlag = true;
          _this6.userList = [];
          _this6.xi = false;
          _this6.pageIndex = 1;

          _this6.huoqu();
        }
      }).catch(function () {
        _this6.$utils.Tools.httpError();
      });
    }
  },
  watch: {
    showFlag: function showFlag(val) {
      this.$emit('input', val);

      if (val) {
        var _this$userChecked;

        this.depList = [];
        this.userList = [];
        this.depChecked = [];
        this.userChecked = [];
        this.searchList = [];
        this.userSubList = [];
        this.depSubList = [];
        this.selectList = [];
        this.userInfo = [];
        this.allCheck = false;
        this.userSum = 0;
        this.depSum = 0;
        this.searchValue = '';
        this.searchFlag = false;
        this.detailFlag = false;
        this.pagePath = [];
        this.initFlag = false;

        (_this$userChecked = this.userChecked).push.apply(_this$userChecked, _toConsumableArray(this.infoChecked));

        this.userSum = this.userChecked.length;
        this.depSerials = this.depSerial;
        this.getOrgList(this.depSerial, this.depName, true);
      }
    },
    value: {
      handler: function handler(val) {
        this.showFlag = val;
      },
      immediate: true
    },
    allCheck: function allCheck(check) {
      var _this7 = this;

      if (check) {
        if (this.depList.length > 0) {
          this.depList.filter(function (dep) {
            if (_this7.depChecked.indexOf(dep.depSerial) === -1) {
              _this7.depChecked.push(dep.depSerial);
            }
          });
        }

        if (this.userList.length > 0) {
          this.userList.filter(function (user) {
            if (_this7.userChecked.indexOf(user.userSerial) === -1) {
              _this7.userChecked.push(user.userSerial);
            }
          });
        }
      } else {
        if (this.depList.length > 0) {
          this.depList.filter(function (dep) {
            for (var index in _this7.depChecked) {
              if (_this7.depChecked[index] === dep.depSerial) {
                _this7.depChecked.splice(index, 1);
              }
            }
          });
        }

        if (this.userList.length > 0) {
          this.userList.filter(function (user) {
            for (var index in _this7.userChecked) {
              if (_this7.userChecked[index] === user.userSerial) {
                _this7.userChecked.splice(index, 1);
              }
            }
          });
        }
      }
    }
  }
};